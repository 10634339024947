.card {
    transform: scale(1);
    transition: 0.2s ease-in-out;
    height: 100%;

    &:hover {
        box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.04);

        img {
            transition: 0.6s ease-in-out;
            transform: scale(1.2);
        }
    }
}
