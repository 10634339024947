@import '../../styles/scss/_variables.scss';

.portfolio {
    // background-color: $gray-100;

    // a {
    //     width: 100%;
    //     height: 100%;
    // }

    .card {
        border: none;
    }

    hr.grey::after {
        box-shadow: 0 0 0 5px #f8f9fa;
    }
}
